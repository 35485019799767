<template>
  <div>
    <v-dialog v-model='isEditVat' persistent max-width='500'>
      <v-card>
        <v-card-title>
          แก้ไขอัตราภาษี
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formEditVat' @submit.prevent='updateVat'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='vat_value' filled dense
                            label='มูลค่าภาษี'
                            placeholder='มูลค่าภาษี'
                            hide-details='auto'
                            type='number'
                            :append-icon='mdiPercentOutline'
                            :rules='[required]'
              ></v-text-field>
            </v-col>

          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isEditVat`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import { api } from '@/services/api'
import store from '@/store'
import {mdiPercentOutline} from "@mdi/js";
export default {
  model: {
    prop: 'isEditVat',
    event: 'update:isEditVat',
  },
  props: {
    isEditVat: {
      type: Boolean,
      default: false,
    },
    dataEditVat: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { root, emit }) {
    const vat_value = ref('')
    const loading = ref(false)
    const formEditVat = ref(null)

    const updateVat = () => {
      const isFormValid = formEditVat.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.put({
        path: '/center/vat',
        param: props.dataEditVat.vat_id,
        body: {
          vat_value: vat_value.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isEditVat', false)
          emit('onUpdate')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isEditVat, value => {
      if (value) {
        loading.value = false
        const vatLocal = JSON.parse(JSON.stringify(props.dataEditVat))
        vat_value.value = vatLocal.vat_value
      }
    })

    return {
      formEditVat,
      loading,
      mdiPercentOutline,
      vat_value,
      required,
      updateVat,
    }
  },

}
</script>

<style scoped>

</style>
