<template>
  <div>
    <v-select filled label='สถานะ' :items='options'
              dense
              hide-details
              v-model='selected'
              @change='$emit(`selection`, selected)'
    ></v-select>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const selected = ref('1')
    const options = [
      { text: 'ทั้งหมด', value: 'all' },
      { text: 'ปกติ', value: '1' },
      { text: 'ระงับ', value: '0' },
    ]
    return { selected, options }
  },

}
</script>

<style scoped>

</style>
