<template>
  <div>
    <v-dialog v-model='isAddNewVat' persistent max-width='500'>
      <v-card>
        <v-card-title>
          เพิ่มอัตราภาษี
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddNewsVat' @submit.prevent='createVat'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='vat_value' filled dense
                            label='มูลค่าภาษี'
                            placeholder='มูลค่าภาษี'
                            hide-details='auto'
                            type='number'
                            :rules='[required]'
                            :append-icon='mdiPercentOutline'
              ></v-text-field>
            </v-col>

          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isAddNewVat`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import { api } from '@/services/api'
import store from '@/store'
import {mdiPercentOutline} from "@mdi/js";
export default {
  model: {
    prop: 'isAddNewVat',
    event: 'update:isAddNewVat',
  },
  props: {
    isAddNewVat: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const vat_value = ref('')
    const loading = ref(false)
    const formAddNewsVat = ref(null)

    const createVat = () => {
      const isFormValid = formAddNewsVat.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.post({
        path: '/center/vat',
        body: {
          vat_value: vat_value.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewVat', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isAddNewVat, value => {
      if (value) {
        loading.value = false
        vat_value.value = ''
      }
    })

    return {
      formAddNewsVat,
      loading,
      vat_value,
      mdiPercentOutline,
      required,
      createVat,
    }
  },

}
</script>

<style scoped>

</style>
