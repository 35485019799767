import { ref, onMounted } from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import { api } from '@/services/api'

export default function uesVat() {
  const textSearch = ref('')
  const headers = ref([
    {
      text: '#',
      value: 'vat_id',
      width: '50',
    },
    {
      text: 'อัตราภาษี',
      value: 'vat_value',
      align: 'center',
    },
    {
      text: 'สถานะ',
      value: 'vat_active',
    },
    {
      text: 'ตัวเลือก',
      value: 'actions',
      width: '200',
      align: 'center',
    },
  ])
  const itemsPerPage = ref(10)
  const status = ref('1')
  const dataList = ref([])
  const loading = ref(false)
  const isUpdateStatus = ref(false)
  const dataEdit = ref({})
  const footer = ref(footerDataTables)
  const isEdit = ref(false)
  const isAdd = ref(false)
  const changeStatus = (id) => {
    status.value = id
    getVat()
  }

  const getVat = () => {
    loading.value = true
    api.get({
      path: '/center/vats',
      param: `?search=${textSearch.value}&active=${status.value}`,
    }).then(({ data }) => {
      dataList.value = data
      loading.value = false
    }).catch(error => {
      console.log('error :', error)
      loading.value = false
    })
  }

  onMounted(() => {
    getVat()
  })

  return {
    textSearch,
    headers,
    itemsPerPage,
    isAdd,
    isEdit,
    dataEdit,
    footer,
    isUpdateStatus,
    status,
    dataList,
    loading,
    getVat,
    changeStatus,
  }
}
