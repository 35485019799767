<template>
  <div>
    <v-card>
      <v-card-title>
        <span>
          ภาษีมูลค่าเพิ่ม
        </span>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click='isAdd=true'>
          เพิ่มอัตราภาษี
        </v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus' />
        </v-col>
      </v-row>
      <v-data-table
        :headers='headers'
        :items='dataList'
        :items-per-page='itemsPerPage'
        :loading='loading'
        no-data-text='ไม่มีข้อมูล !'
        loading-text='กำลังโหลดข้อมูล...'
        :footer-props='footer'
        no-results-text='ไม่มีข้อมูล !'
        class='elevation-1'
      >
        <template #[`item.vat_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.vat_value`]='{ item }'>
          {{ item.vat_value }} %
        </template>
        <template #[`item.vat_active`]='{ item }'>
          <StatusBlock :status='String(item.vat_active)' />
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                icon
                v-bind='attrs'
                @click='dataEdit = item;isEdit = true'
                v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                icon
                v-bind='attrs'
                @click='dataEdit = item;isUpdateStatus = true'
                v-on='on'
              >
                <v-icon>{{ item.vat_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.vat_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewVat v-model='isAdd' @onAdd='getVat' />
    <EditVat v-model='isEdit' :data-edit-vat='dataEdit' @onUpdate='getVat' />
    <SuspendVat v-model='isUpdateStatus' :data-update='dataEdit' @onUpdate='getVat'/>
  </div>
</template>

<script>
import FilterStatus from '@/components/FilterStatus.vue'
import useVat from './useVat'
import StatusBlock from '@/components/StatusBlock.vue'
import { mdiPencilOutline, mdiDeleteOutline, mdiCheck } from '@mdi/js'
import AddNewVat from '@/views/center/vat/AddNewVat'
import EditVat from '@/views/center/vat/EditVat'
import SuspendVat from '@/views/center/vat/SuspendVat'

export default {
  props: {},
  components: {
    EditVat,
    FilterStatus,
    AddNewVat,
    SuspendVat,
    StatusBlock,
  },
  setup(_, { root, emit }) {
    return {
      ...useVat(),
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiCheck,
    }
  },

}
</script>

<style scoped>

</style>
